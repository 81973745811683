<template lang="html">
  <v-card>
    <v-card-text>
      <v-container fluid pa-0>
        <v-row>
          <v-col cols="12">
            <span class="headline">ลงทะเบียนผู้ใช้งาน</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-form-pad v-model="registerData" ref="registerForm">
              <template v-slot="{data, rules}">
                <v-container fluid>
                  <v-row>
                    <v-col cols="10" md="6">
                      <v-text-field :rules="[rules.require()]" v-model="data.username" label="รหัสผู้ใช้งาน (เลขประจำตัวประชาชน)"></v-text-field>
                    </v-col>
                    <v-col cols="2" md="6">
                      <v-btn color="primary" :loading="isLoadDataFromIDCard" @click="getDataIDCard"><v-icon>mdi-smart-card-reader-outline</v-icon> อ่านบัตร</v-btn>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field :rules="[rules.require()]" v-model="data.name" label="ชื่อ-นามสกุล ผู้ใช้งาน"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="data.email" label="Email" v-permission="'transaction-add-discount'"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-master-field :rules="[rules.require()]" v-model="data.position" groupKey="$UserPosition" label="ตำแหน่ง"></v-master-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="data.licenseNo" label="เลขใบประกอบวิชาชีพ"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field :rules="[rules.require(),rules.lengthGreater(6,'รหัสผ่านต้องความยาวอย่างน้อย 6 ตัวอักษร')]" v-model="data.password" label="รหัสผ่าน" type="password"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field :rules="[rules.require(),(v)=>{return v==data.password || 'รหัสผ่านไม่ตรงกัน'}]" v-model="data.password_confirmation" label="ยืนยันรหัสผ่าน" type="password"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-form-pad>
            <v-btn color="primary" @click.native="saveUser" :loading="isSavingUser">บันทึกข้อมูล</v-btn>&nbsp;
            <v-btn text @click.native="reset">ล้างข้อมูล</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import getIDCard from '@/modules/getIDCard'
import api from 'tantee-common/lib/api'

export default {
  data: ()=>({
    registerData: {},
    isSavingUser: undefined,
    isLoadDataFromIDCard: false,
  }),
  methods: {
    saveUser() {
      this.isSavingUser = true
      if (this.$refs.registerForm.validate()) {
        this.$confirm('ต้องการบันทึกข้อมูลและสร้างผู้ใช้ใหม่').then((res)=>{
          if (res) {
            api.post(['User','UserController','register'],this.registerData).then((returnData)=> {
              if (returnData.success) {
                this.$notify('สร้างผู้ใช้ใหม่เรียบร้อยแล้ว')
                this.$refs.registerForm.reset()
              } else {
                returnData.errorTexts.forEach((item)=>{
                  this.$store.dispatch('addAlertItem',{
                    alertText: item.errorText,
                    alertType: 'error'
                  })
                })
              }
            }).finally(()=>{
              this.isSavingUser = false
            })
          } else {
            this.isSavingUser = false
          }
        })
      } else {
        this.isSavingUser = false
      }
    },
    reset() {
      this.registerData = {}
    },
    getDataIDCard() {
      this.isLoadDataFromIDCard = true
      getIDCard().then((cardData)=>{
        if (cardData.patient && cardData.patient.personIdDetail) {
          this.registerData['username'] = cardData.patient.personIdDetail.NationalID
          this.registerData['name'] = cardData.patient.personIdDetail.ThaiFirstName+' '+cardData.patient.personIdDetail.ThaiLastName
        }
      })
      .catch((e)=>void e)
      .finally(()=>{
        this.isLoadDataFromIDCard = false
      })
    },
  }
}
</script>

<style lang="css" scoped>
</style>
